<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <!-- <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p> -->
    <div class="control-container" style="display:none">
      <div class="rtc-control-container">
        <el-button
          class="button"
          type="primary"
          size="small" :disabled="isJoining || isJoined" @click="handleJoinRoom">{{ $t('Join Room') }}</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          size="small" :disabled="isPublishing || isPublished" @click="handlePublish">{{ $t('Publish') }}</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary" size="small" @click="handleUnpublish">{{ $t('Unpublish') }}</el-button>
        <el-button
          class="button"
          type="primary" size="small" @click="handleLeave">{{ $t('Leave Room') }}</el-button>
      </div>
      <div v-if="isHostMode" class="screen-share-control-container">
        <el-button
          class="button"
          type="primary"
          size="small"
          :disabled="isShareJoined && isSharePublished"
          @click="handleStartScreenShare">{{ $t('Start Screen Share') }}</el-button>
        <el-button
          class="button"
          type="primary" size="small" @click="handleStopScreenShare">{{ $t('Stop Screen Share') }}</el-button>
      </div>
      <div class="transcribe-control-container">
        <el-button
          class="button"
          type="primary"
          size="small" @click="clicks()" title="点击开始录制">{{txt}}</el-button>
      </div>
    </div>
    <div class="info-container" :class="$isMobile && 'info-container-mobile'" style="display:none">
      <!-- Log 展示区域 -->
      <div v-if="isHostMode" class="log-container" ref="logContainer">
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
          <!-- <el-empty v-if="item.type === 'failed'" :description="item.log"></el-empty> -->
        </div>
      </div>

    </div>
    <el-empty v-if="isshow" description="无法获取摄像头或麦克风"></el-empty> 
    <div v-if="isshow" class="param-container" :class="$isMobile && 'param-container-mobile'">
      <p class="title">设备选择</p>
      <comp-device-select
        deviceType="camera" @change="handleValueChange($event, 'cameraId')"></comp-device-select>
      <comp-device-select
        deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>
    </div>
    <div class="container-box" ref="containerbox">
      <!-- 本地流区域 -->
      <div v-if="localStream" class="local-stream-container">
        <!-- 本地流播放区域 -->
        <div id="localStream" class="local-stream-content"></div>
        <!-- 本地流操作栏 -->
        <div v-if="isPlayingLocalStream" class="local-stream-control">
          <div class="audio-control control">
            <span v-if="!isMutedAudio" @click="muteAudio">
              <!-- <svg-icon icon-name="audio" class="icon-class"></svg-icon> -->
              <i class="icon-class audio-one"></i>
            </span>
            <span v-if="isMutedAudio" @click="unmuteAudio">
              <!-- <svg-icon icon-name="audio-muted" class="icon-class"></svg-icon> -->
              <i class="icon-class audio-two"></i>
            </span>
            <div>麦克风</div>
          </div>
          <div class="video-control control">
            <span v-if="!isMutedVideo" @click="muteVideo">
              <i class="icon-class video-one"></i>
            </span>
            <span v-if="isMutedVideo"  @click="unmuteVideo">
              <i class="icon-class video-two"></i>
            </span>
            <div>摄像头</div>
          </div>
          <div class="video-control control" @click="clicks">
            <span v-if="!status" class="icon-class">
              <i class="icon-class transcribe-one"></i>
            </span>
            <span v-if="status" class="icon-class">
              <i class="icon-class transcribe-two"></i>
            </span>
            <div>屏幕录制</div>
          </div>
          <div class="video-control control" @click="showMinute">
            <span v-if="!minute" class="icon-class">
              <i class="icon-class minute-one"></i>
            </span>
            <span v-if="minute" class="icon-class">
              <i class="icon-class minute-two"></i>
            </span>
            <div>接访纪要</div>
          </div>
          <div class="video-control control" @click="sets">
            <span v-if="!set" class="icon-class">
              <i class="icon-class set-one"></i>
            </span>
            <span v-if="set" class="icon-class">
              <i class="icon-class set-two"></i>
            </span>
            <div>设置</div>
          </div>
          <div class="video-control control" @click="showScreen">
            <span v-if="!fullscreen" class="icon-class">
              <i class="icon-class screen-one"></i>
            </span>
            <span v-if="fullscreen" class="icon-class">
              <i class="icon-class screen-two"></i>
            </span>
            <div>{{!fullscreen?'全屏' : '推出全屏'}}</div>
          </div>
          <div class="video-control control" @click="handleLeave">
            <span v-if="!close" class="icon-class">
              <i class="icon-class colse-one"></i>
            </span>
            <span v-if="close" class="icon-class">
              <i class="icon-class colse-two"></i>
            </span>
            <div>关闭接访</div>
          </div>
        </div>
        <!-- 设备选择区域 -->
        <div class="param-container" :class="$isMobile && 'param-container-mobile'" v-if="set">
          <p class="title">设备选择</p>
          <comp-device-select
            deviceType="camera" @change="handleValueChange($event, 'cameraId')"></comp-device-select>
          <comp-device-select
            deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>
        </div>
        <div class="tit-box" ref="box" v-if="minute">
          <div class="title" @mousedown="draw">
            <span>接访标题</span>
            <div class="icon-btn">
              <div class="video-control control" @click="fullclose">
                <span v-if="!full" class="icon-class">
                  <i class="el-icon-full-screen"></i>
                </span>
                <span v-if="full" class="icon-class">
                  <i class="el-icon-crop"></i>
                </span>
              </div>
              <div class="video-control control" @click="minute = false">
                <span class="icon-class">
                  <i class="el-icon-switch-button"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="cont-box">
            <div class="top">
              <div class="inp">
                <span class="name">信访人</span><el-input class="input" placeholder="请输入内容" v-model="username" clearable></el-input>
              </div>
              <div class="inp">
                <span class="name">接访人</span><el-input class="input" placeholder="请输入内容" v-model="checkname" clearable></el-input>
              </div>
              <div class="inp">
                <span class="name">记录人</span><el-input class="input" placeholder="请输入内容" v-model="name" clearable></el-input>
              </div>
              <!-- <div class="inp">
                <span class="name">接访日期</span><el-date-picker v-model="day" type="date" placeholder="选择日期"></el-date-picker>
              </div>
              <div class="inp">
                <span class="name">开始时间</span><el-date-picker v-model="visitstarttime" type="datetime" placeholder="选择日期时间"></el-date-picker>
              </div>
              <div class="inp">
                <span class="name">结束时间</span><el-date-picker v-model="time" type="datetime" placeholder="选择日期时间"></el-date-picker> -->
              <!-- </div> -->
            </div>
            <div class="text">
              <span>接访内容</span>
              <div class="text-box">
                <div class="text-head">请在下方输入接访内容</div>
                <el-input class="textarea" type="textarea" v-model="textarea2"></el-input>
              </div>
            </div>
            <div class="buttom">
              <el-button @click="minute = false">返回</el-button>
              <el-button type="primary" @click="submit">提交</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 远端流区域 -->
      <div class="remote-container" ref="remote" @mousedown="move">
        <div
          v-for="(item) in remoteStreamList"
          :key="item.getUserId()"
          :id="item.getUserId()"
          class="remote-stream-container">
        </div>
      </div>
    </div>
    <video style="width: 400px;height: 400px;" id="videosreplay" src="" ref="videosreplay"></video>
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from  './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import compDeviceSelect from '@/components/comp-device-select.vue';
import{addVisitRecording,endVisit} from '@/utils/api'
export default {
  name: 'compRoom',
  components: {
    compDeviceSelect,
  },
  mixins: [rtc, shareRtc],
  props: {
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
    username: String,
    checkname: String,
    visitstarttime: String,
    id: String,
    name: String,
    textarea2: String,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      fullscreen: false,
      timeout: 0,
      recordedBlobs: [],
      fileName: "",
      txt: "开始录制",
      status: false,
      set: false,
      minute: false,
      close: false,
      stream: null,
      options: {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 4500000,
        mimeType: "video/webm",
      },
      startTime: 0,
      timeLong: 0,
      timeLongs: null,
      endTime: 0,
      username:'',
      checkname:'',
      visitstarttime:'',
      name:'',
      day:'',
      date:'',
      textarea2:'',
      full: false,
      isshow:false
    };
  },
  mounted() {
    const that = this;
    window.addEventListener('resize', () => {
      let box = this.$refs.remote;
      if (!that.checkFull()) {
        // 退出全屏后要执行的动作
        that.fullscreen = false;
        box.style.left = 0;
        box.style.top = 0;
      }
    });
    setTimeout(function(){ 
      that.handleJoinRoom()
    },100)
    
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
    status(a) {
      if (a) {
        this.txt = "停止录制";
      } else {
        this.txt = "开始录制";
      }
    },
  },
  methods: {
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      console.log(sdkAppId, secretKey, roomId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t('Please enter sdkAppId and secretKey'));
          return;
        }
        if (!this.userId || !this.roomId) {
          alert(this.$t('Please enter userId and roomId'));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert(this.$t('Please reacquire the invitation link'));
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
    },
    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      endVisit({
          visitId:this.id
        }).then(res =>{
          if(res.data.code == 200){
            // console.log(res);
            this.$router.push(`/order-list`)
          }
      })
      await this.leave();
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
      this.isshow = false
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      this.isshow = true
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    checkFull() {
      let isFull = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    // 点击全屏按钮
    showScreen() {
      const element = document.querySelector('.container-box');
      let box = this.$refs.remote;
      if (this.fullscreen) {
        box.style.left = 0;
        box.style.top = 0;
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        box.style.left = 0;
        box.style.top = 0;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    // 拖拽
    move(evt) { 
      let boxBig = this.$refs.containerbox;
      let box = this.$refs.remote;
      let e = evt || window.event;
      //事件源
      let target = e.target || e.srcElement;
      let dis_x = e.offsetX;
      let dis_y = e.offsetY;
      document.onmousemove = function(evt){
        let e = evt || window.event;
        let left = e.clientX - dis_x;
        let top = e.clientY - dis_y;
        //左边界
        if(left <= 0){
            left = 0;
        }else if(left >= boxBig.offsetWidth - box.offsetWidth){ //右边界   
            left = boxBig.offsetWidth - box.offsetWidth;
        }
        //上边界
        if(top <= 0){
            top = 0;
        }else if(top >= boxBig.offsetHeight - box.offsetHeight){ //下边界
            top = boxBig.offsetHeight - box.offsetHeight;
        }
        box.style.left = left + 'px';
        box.style.top = top + 'px';
      }
      document.onmouseup = function(){
        document.onmousemove = null;
      }
      document.ondragstart = function(){
        return false;
      }
    },
    // 点击录制
    clicks() {
      if (this.status) {
        this.stopReset();
        this.status = false;
        clearInterval(this.timesLongs);
      } else {
        this.startScreen();
      }
    },
    stopReset() {
      this.stream.getTracks().forEach((track) => track.stop());
      this.recordedBlobs = [];
    },
    cauTime() {
      this.timeLongs = setInterval(() => {
        this.timeLong = (new Date().getTime() - this.startTime) / 1000;
      }, 200);
    },
    startScreen() {
    //注意,加上 audio: true 之后底部可以拖动的停止就失效了,正在fixing
      navigator.mediaDevices.getDisplayMedia({ video: true, audio: true }).then(
        (stream) => {
          this.status = !this.status;
          // console.log("开始录制");
          this.startTime = new Date().getTime();
          this.cauTime();
          // console.log(stream);
          this.stream = stream;
          
          const mediaRecorder = new MediaRecorder(stream, this.options);
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.status = false;
              clearInterval(this.timeLongs);
              // console.log(
              //   "结束录制,录制时长:" +
              //     this.timeLong+
              //     "s"
              // );
              this.recordedBlobs.push(event.data);
              // console.log(event);
              this.download();
              this.stopReset();
            }
          };
          mediaRecorder.start();
        },
        (error) => (error)
      );
    },
    download() {
      const blob = new Blob(this.recordedBlobs, { type: "video/mp4" });
      // console.log(blob);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      // console.log(url);
      if (this.fileName) {
        a.download = this.fileName + ".mp4";
      } else {
        a.download = new Date() + ".mp4";
      }
      a.click();
      window.URL.revokeObjectURL(url);
    },
    // 设置
    sets() {
      if (this.set) {
        this.set = false
      } else {
        this.set = true
      }
      this.$emit('close',this.set)
    },
    // 接访纪要
    showMinute() {
      if (this.minute) {
        this.minute = false
      } else {
        this.minute = true
      }
    },
    submit(){
      addVisitRecording({
        id:this.id,
        name:this.name,
        text:this.textarea2
      }).then(res =>{
        // console.log(res);
        if(res.data.code ==200){
          this.minute = false
        }
      })
    },
    fullclose(){
      const box = document.querySelector('.tit-box');
      if (this.full) {
        // box.style.width = "950px";
        // box.style.height = "580px";
        if (box.requestFullscreen) {
          box.requestFullscreen();
        } else if (box.webkitRequestFullScreen) {
          box.webkitRequestFullScreen();
        } else if (box.mozRequestFullScreen) {
          box.mozRequestFullScreen();
        } else if (box.msRequestFullscreen) {
          // IE11
          box.msRequestFullscreen();
        }
        this.full = false
        // console.log(1);
      } else {
        // console.log(2);
        // box.style.width = "100vw";
        // box.style.height = "100vh";
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.full = true
      }
    },
    // 关闭接访
    showClose() {
      if (this.close) {
        this.close = false
      } else {
        this.close = true
      }
    },
    handleValueChange(value, key) {
      this[key] = value;
    },
    draw(evt){
      let o_box = this.$refs.box;
      let e = evt || window.event;
      let dis_x = e.offsetX;
      let dis_y = e.offsetY;
      // console.log(e.pageX - dis_x)
      // console.log(e.pageY - dis_y)
      document.onmousemove = function(evt){
          var e = evt || window.event;
          o_box.style.left = e.pageX - dis_x + 'px';
          o_box.style.top = e.pageY - dis_y + 'px';
      }
      o_box.onmouseup = function(){
          document.onmousemove = null;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    display: flex;
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    // .button:not(:first-child) {
    //   margin-left: 2px;
    // }
    .button{
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 150px;
      padding: 10px;
      // margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
  }
  .container-box{
    width: 100%;
    max-height: 690px;
    position: relative;
    // top: 0;
    // left: 0;
    margin-top: 15px;
    display: flex;
    .local-stream-container {
      width: 100%;
      // height: 200px;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 712px;
        height: 126px;
        background: #333333;
        border-radius: 12px 12px 0px 0px;
        // width: 100%;
        // height: 30px;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -356px;
        // background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 10px;
        text-align: center;
        .control {
          margin-left: 10px;
          color: #fff;
        }
        .icon-class {
          display: inline-block;
          color: #fff;
          cursor: pointer;
          width: 50px;
          height: 50px;
        }
        .audio-one{
          background: url(~@/assets/image/audio1.png) no-repeat center center;
          background-size: 100%;
        }
        .audio-two{
          background: url(~@/assets/image/audio2.png) no-repeat center center;
        }
        .video-one{
          background: url(~@/assets/image/video1.png) no-repeat center center;
          background-size: 100%;
        }
        .video-two{
          background: url(~@/assets/image/video2.png) no-repeat center center;
        }
        .transcribe-one{
          background: url(~@/assets/image/transcribe1.png) no-repeat center center;
        }
        .transcribe-two{
          background: url(~@/assets/image/transcribe2.png) no-repeat center center;
        }
        .minute-one{
          background: url(~@/assets/image/minute1.png) no-repeat center center;
        }
        .minute-two{
          background: url(~@/assets/image/minute2.png) no-repeat center center;
        }
        .set-one{
          background: url(~@/assets/image/set1.png) no-repeat center center;
        }
        .set-two{
          background: url(~@/assets/image/set2.png) no-repeat center center;
        }
        
        .screen-one{
          background: url(~@/assets/image/screen1.png) no-repeat center center;
        }
        .screen-two{
          background: url(~@/assets/image/screen2.png) no-repeat center center;
        }
        .colse-one{
          background: url(~@/assets/image/colse1.png) no-repeat center center;
        }
        .colse-two{
          background: url(~@/assets/image/colse2.png) no-repeat center center;
        }
      }
      .param-container{
        padding: 18px 24px;
        width: 539px;
        height: 158px;
        background: #FFFFFF;
        border-radius: 12px;
        position: absolute;
        bottom: 145px;
        left: 50%;
        margin-left: -269px;
        .title{
          font-size: 16px;
          font-weight: 500;
          color: #909398;
          line-height: 22px;
          margin-bottom: 12px;
        }
      }
    }
    .remote-container {
      width: 35%;
      // margin-top: 10px;
      // display: flex;
      // flex-wrap: wrap;
      // background: red;
      position: absolute;
      // top: 0;
      // right: 15px;
      .remote-stream-container {
        // width: 320px;
        // height: 240px;
        width: 100%;
        margin: 0 10px 10px 0;
      }
    }
  }
  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 320px;
      height: 240px;
      margin-top: 10px;
    }
  }
  .tit-box{
    width: 950px;
    height: 580px;
    background: #FFFFFF;
    // background: red;
    border-radius: 3px;
    position: fixed;
    top: 0;
    border: 1px solid #909398;
    .title{
      height: 90px;
      background: #F4F4F4;
      line-height: 90px;
      text-align: center;
      position: relative;
      .icon-btn{
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        i{
          font-size: 24px;
          margin-right: 20px;
        }
      }
    }
    .cont-box{
      height: calc(100% - 90px);
      .top{
        display: flex;
        // justify-content: space-between;
        padding: 30px 37px;
        // margin: 30px 0;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        .inp{
          display: flex;
          align-items: center;
          margin-right: 25px;
          .name{
            margin-right: 9px;
          }
        }
        .input{
          width: 175px;
        }
      }
      .text{
        height: calc(100% - 200px);
        padding: 0 20px;
        display: flex;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 37px;
        .text-box{
          width: 100%;
          height: 100%;
          background: #FFFFFF;
          border-radius: 3px;
          border: 1px solid #E2E2E2;
          margin-left: 10px;
          .text-head{
            height: 37px;
            background: #FFFFFF;
            border-radius: 3px 3px 0px 0px;
            border-bottom: 1px solid #E2E2E2;
            font-size: 18px;
            font-weight: 400;
            color: #AAAAAA;
            line-height: 37px;
            text-indent:10px
          }
          .textarea{
            width: 100%;
            height: 100%;
          }
          ::v-deep .el-textarea__inner{
            width: 100%;
            // height: 740px;
            border: none;
          }
        }
      }
      .buttom{
        float: right;
        margin-top: 30px;
        margin-right: 30px !important;
      }
      .el-button{
        width: 116px;
        height: 52px;
        font-size: 21px;
        // background: #288BFD;
        border-radius: 4px;
      }
    }
  }
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
